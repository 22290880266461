import { Routes } from '@angular/router';
import { authGuard } from '../guards/auth.guard';
import { UserService } from '../store/user/user.service';
import { AnimalsService } from '../store/animals/animals.service';
import { BrowserService } from '../store/browser/browser.service';
import { RescueOrgService } from '../store/rescue-org/rescue-org.service';
import { loginGuard } from "../guards/login.guard";

export const routes: Routes = [
  {
    title: 'Bejelentkezés',
    path: 'azonositas',
    providers: [UserService],
    loadComponent: () => import('../pages/login/login.component').then((c) => c.default),
    canActivate: [loginGuard]
  },
  {
    title: 'Állatok',
    path: 'allatok',
    providers: [UserService, AnimalsService, BrowserService, RescueOrgService],
    loadComponent: () => import('../pages/animals/animals.component').then((c) => c.default),
    loadChildren: () => import('./animal.routes'),
    canActivate: [authGuard]
  },
  {
    title: 'Irányítópult',
    path: 'iranyitopult',
    providers: [UserService, AnimalsService, BrowserService, RescueOrgService],
    loadComponent: () => import('../pages/dashboard/dashboard.component').then((c) => c.default),
    canActivate: [authGuard]
  },
  {
    title: 'Profil Beállítások',
    path: 'profil-beallitasok',
    providers: [UserService, BrowserService, RescueOrgService],
    loadComponent: () => import('../pages/profile-settings/profile-settings.component').then((c) => c.default),
    loadChildren: () => import('./profile.routes'),
    canActivate: [authGuard]
  },
  {
    title: 'Menhely Beállítások',
    path: 'menhely-beallitasok/:id',
    providers: [UserService, BrowserService, RescueOrgService],
    loadComponent: () => import('../pages/organization-settings/organization-settings.component').then((c) => c.default),
    loadChildren: () => import('./rescue-org.routes'),
    canActivate: [authGuard]
  },
  {
    path: 'auth',
    redirectTo: '/azonositas',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/azonositas',
    pathMatch: 'full'
  }
  // {
  //   path: '**',
  //   loadComponent: () => <COMPONENT>
  // }
];
