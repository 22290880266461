import { ChangeDetectionStrategy, Component, OnInit }            from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MenuModule }                                                       from 'primeng/menu';
import { MenuItem, PrimeIcons }                                  from 'primeng/api';

@Component({
  selector: 'cpm-navbar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    MenuModule
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit {
  menuItems: MenuItem[] | undefined;

  ngOnInit() {
    this.menuItems = [
      {
        label: 'Irányítópult',
        routerLink: '/iranyitopult',
        icon: PrimeIcons.HOME
      },
      {
        label: 'Állatok',
        icon: PrimeIcons.HEART,
        routerLink: '/allatok',
        routerLinkActiveOptions: {
          exact: false
        }
      }
    ]
  }
}
