<p-toolbar [styleClass]="'cpm-app-toolbar'">
  <div class="p-toolbar-group-start">
    <cpm-logo></cpm-logo>
    <cpm-menu-navbar></cpm-menu-navbar>
    <cpm-divider orientation="vertical"></cpm-divider>
    @if ((userQuery.selectConnectedOrgs$ | async)) {
      <p-dropdown
        styleClass="cpm-rescue-org-selector"
        [disabled]="rescueOrgOptions.length == 1"
        [dropdownIcon]="rescueOrgOptions.length == 1 ? 'none' : 'pi pi-angle-down'"
        emptyMessage="Még nem tartozol menhelyhez"
        [options]="rescueOrgOptions"
        [(ngModel)]="selectedOrgId"
        (ngModelChange)="selectOrgId($event)"
        optionLabel="displayValue"
        optionValue="value"
        placeholder="Válassz menhelyet"></p-dropdown>
    }
  </div>
  <div class="p-toolbar-group-center">
    @if ((userQuery.selectConnectedOrgs$ | async)) {
      <p-dropdown
        styleClass="cpm-rescue-org-selector"
        [disabled]="rescueOrgOptions.length == 1"
        [dropdownIcon]="rescueOrgOptions.length == 1 ? 'none' : 'pi pi-angle-down'"
        emptyMessage="Még nem tartozol menhelyhez"
        [options]="rescueOrgOptions"
        [(ngModel)]="selectedOrgId"
        (ngModelChange)="selectOrgId($event)"
        optionLabel="displayValue"
        optionValue="value"
        placeholder="Válassz menhelyet"></p-dropdown>
    }
  </div>
  <div class="p-toolbar-group-end">
    <div class="cpm-flex cpm-flex-gap-08">
      <button tooltipPosition="bottom" positionTop="8" pTooltip="Segítség" (click)="showHelp()" class="cpm-i-need-help">
        <span class="material-symbols-outlined">help</span>
      </button>
      <cpm-avatar />
    </div>
  </div>
</p-toolbar>
<p-dialog
  header="Segítségre van szükségem"
  [draggable]="false"
  position="topright"
  [modal]="false"
  [(visible)]="isHelpShown"
  [style]="{ width: '25rem' }"
>
  <p>Szia, amennyiben elakadtál minden erőnkkel azon vagyunk, hogy megoldjuk a problémád!</p>
  <br>
  <p>Jelenleg a fejlesztői csapatot egy személy képviseli:</p>
  <br>
  <p><b>Név: </b>Polyák Donát</p>
  <p><b>Email: </b><a href="mailto:polyakdonat@gmail.com">polyakdonat&#64;gmail.com</a></p>
  <p><b>Telefon: </b> <a href="tel:+36209440504">+36 20 944 0504</a></p>
  <p><b>Munka órák: </b> 8:00 - 20:00</p>
  <br>
  <p>Sürgős problémákkal keresd <a target="_blank" href="https://www.facebook.com/donat.polyak/">facebookon</a></p>
</p-dialog>
