@if (userQuery.isLoggedIn) {
  <main [class.cpm-settings]="router.url.includes('beallitasok')">
    <cpm-toolbar id="cpm-toolbar" />
    @if (!router.url.includes('beallitasok')) {
      <cpm-navbar id="cpm-sidenav"/>
    }
    <div id="cpm-content">
      <router-outlet />
    </div>
  </main>
} @else {
  <router-outlet />
}
<cpm-confirmation-dialog></cpm-confirmation-dialog>
<p-toast [breakpoints]="{'800px': { width: '95svw', right: '0', left: '0', margin: 'auto' }}"></p-toast>
