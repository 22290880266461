import { Component, Inject, inject, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, DOCUMENT, ViewportScroller } from '@angular/common';
import { UserQuery }         from '../../store/user/user.query';
import { RescueOrgQuery }    from '../../store/rescue-org/rescue-org.query';
import { BrowserQuery } from '../../store/browser/browser.query';
import { BrowserService } from '../../store/browser/browser.service';
import { AvatarComponent } from '../avatar/avatar.component';
import { ToolbarModule } from 'primeng/toolbar';
import { DividerModule } from 'primeng/divider';
import { UserService } from '../../store/user/user.service';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { MenuNavbarComponent } from '../menu-navbar/menu-navbar.component';
import { LogoComponent } from '../logo/logo.component';
import {ActivatedRoute, Router} from '@angular/router';
import { DividerComponent } from '../divider/divider.component';
import {DialogModule} from "primeng/dialog";
import {TooltipModule} from "primeng/tooltip";

interface RescueOrgOption {
  value: string;
  displayValue: string;
}

const LAST_ORG_ID_KEY = 'lastSelectedOrgId';

@Component({
  selector: 'cpm-toolbar',
  standalone: true,
  imports: [
    AsyncPipe,
    AvatarComponent,
    ToolbarModule,
    DividerModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    MenuNavbarComponent,
    LogoComponent,
    DividerComponent,
    DialogModule,
    TooltipModule
  ],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent implements OnInit, OnDestroy {
  userQuery = inject(UserQuery);
  userService = inject(UserService);
  router = inject(Router);

  rescueOrgOptions!: RescueOrgOption[];
  userSubscription: Subscription | undefined;
  rescueOrgIdSubscription: Subscription | undefined;

  selectedOrgId: string = '';
  isHelpShown = false;

  ngOnInit() {
    this.rescueOrgIdSubscription = this.userQuery.selectSelectedRescueOrgId$.subscribe((orgId) => {
      if (this.selectedOrgId !== orgId) {
        this.userService.setSelectedRescueOrgId(orgId);
        this.selectedOrgId = orgId;
      }
    });
    this.userSubscription = this.userQuery.selectConnectedOrgs$.subscribe((orgs) => {
      if (!this.userQuery.isLoggedIn) return;
      const sessionOrgId = window.sessionStorage.getItem(LAST_ORG_ID_KEY);
      if (sessionOrgId === '0') {
        window.sessionStorage.removeItem(LAST_ORG_ID_KEY);
        if (this.userQuery.favouriteRescueOrg) {
          this.selectedOrgId = sessionOrgId ? sessionOrgId : this.userQuery.favouriteRescueOrg.id;
        } else {
          this.selectedOrgId = this.userQuery.connectedOrgs[0].id;
        }
      } else {
        if (this.userQuery.favouriteRescueOrg) {
          this.selectedOrgId = sessionOrgId ? sessionOrgId : this.userQuery.favouriteRescueOrg.id;
        } else {
          this.selectedOrgId = this.userQuery.connectedOrgs[0].id;
        }
      }
      this.userService.setSelectedRescueOrgId(this.selectedOrgId);
      this.rescueOrgOptions = orgs.map((org) => {
        return {
          value: org.id,
          displayValue: org.name
        }
      });
    });
  }

  selectOrgId(newId: string) {
    this.userService.setSelectedRescueOrgId(newId);
    window.sessionStorage.setItem(LAST_ORG_ID_KEY, newId);
    const contentElement = document.getElementById('cpm-content');
    if (contentElement) {
      contentElement.scrollTop = 0;
    }
    if (this.router.url.includes('menhely-beallitasok')) {
      this.router.navigate(['menhely-beallitasok', newId]);
    }
  }

  showHelp() {
    this.isHelpShown = true;
  }

  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
  }
}
