import { Component, inject, OnInit } from '@angular/core';
import { UserQuery } from '../../store/user/user.query';
import { AsyncPipe, NgIf } from '@angular/common';
import { UserService } from '../../store/user/user.service';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { MenuItem, PrimeIcons, SharedModule } from 'primeng/api';
import { Router } from '@angular/router';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'cpm-avatar',
  standalone: true,
  imports: [
    AsyncPipe,
    AvatarModule,
    MenuModule,
    SharedModule,
    NgIf,
    RippleModule
  ],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss'
})
export class AvatarComponent implements OnInit {
  userQuery = inject(UserQuery);
  private userService = inject(UserService);
  private router = inject(Router);

  items: MenuItem[] = [];

  ngOnInit() {
    this.items = [
      {
        label: `<div>Bejelentkezve, <h6 style="margin-top: 4px;">${this.userQuery.fullName}</h6></div>`,
        escape: false,
        items: [
          {
            label: 'Profil',
            iconName: 'account_circle',
            icon: 'material-symbols-outlined',
            command: () => {
              this.router.navigate(['profil-beallitasok']);
            }
          },
          {
            label: 'Menhely',
            iconName: 'diversity_1',
            icon: 'material-symbols-outlined',
            command: () => {
              this.router.navigate(['menhely-beallitasok', this.userQuery.selectedRescueOrgId]);
            }
          },
          {
            label: 'Kijelentkezés',
            icon: 'material-symbols-outlined',
            iconName: 'power_settings_new',
            command: () => {
              this.userService.logout();
            }
          }
        ]
      }
    ]
  }
}
