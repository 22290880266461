@if (userQuery.selectMonogram$ | async; as monogram) {
  <p-menu #menu [model]="items" [popup]="true">
    <ng-template pTemplate="item" let-item>
      <a pRipple class="flex align-items-center p-menuitem-link">
        <span [class]="item.icon">{{ item.iconName }}</span>
        <span class="ml-2">{{ item.label }}</span>
        <span *ngIf="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
            {{ item.shortcut }}
        </span>
      </a>
    </ng-template>
  </p-menu>
  <p-avatar class="cpm-avatar" (click)="menu.toggle($event)" shape="circle" size="normal" [label]="monogram"></p-avatar>
}

