import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { NavbarComponent } from '../navbar/navbar.component';
import { MenuModule } from 'primeng/menu';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LogoComponent } from '../logo/logo.component';

@Component({
  selector: 'cpm-menu-navbar',
  standalone: true,
  imports: [
    ButtonModule,
    SidebarModule,
    NavbarComponent,
    MenuModule,
    LogoComponent
  ],
  templateUrl: './menu-navbar.component.html',
  styleUrl: './menu-navbar.component.scss'
})
export class MenuNavbarComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  menuItems: MenuItem[] | undefined;
  sidebarVisible = false;
  routerSub: Subscription | undefined;

  ngOnInit() {
    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.sidebarVisible = false;
      }
    })
    this.menuItems = [
      {
        label: 'Irányítópult',
        routerLink: '/iranyitopult',
        icon: PrimeIcons.HOME
      },
      {
        label: 'Állatok',
        icon: PrimeIcons.HEART,
        routerLink: '/allatok',
        routerLinkActiveOptions: {
          exact: false
        }
      }
    ]
  }

  ngOnDestroy() {
    this.routerSub?.unsubscribe();
  }
}
