import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { UserService }                                from '../store/user/user.service';
import { UserQuery }                                  from '../store/user/user.query';
import { NgClass, NgIf } from '@angular/common';
import { ToolbarComponent }                            from './toolbar/toolbar.component';
import { BrowserService } from '../store/browser/browser.service';
import { NavbarComponent } from './navbar/navbar.component';
import { RescueOrgService } from '../store/rescue-org/rescue-org.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ToastModule } from 'primeng/toast';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cpm-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NgIf,
    ToolbarComponent,
    NavbarComponent,
    ConfirmationDialogComponent,
    ToastModule,
    NgClass,
    ToastModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  public userService = inject(UserService);
  public userQuery = inject(UserQuery);
  private rescueOrgService = inject(RescueOrgService);
  private browserService = inject(BrowserService);
  public router = inject(Router);
  private translateService = inject(TranslateService);
  private config = inject(PrimeNGConfig);
  translateSubscription: Subscription | undefined;

  ngOnInit() {
    this.translateService.addLangs(['hu']);
    this.translateService.setDefaultLang('hu');
    this.translate('hu');
    this.userService.reAuth();
    this.browserService.listenForRouterEvent();
    this.browserService.listenForThemePreferences();
    this.rescueOrgService.getRescueOrgInfo();
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateSubscription = this.translateService.stream('primeng').subscribe(res => this.config.setTranslation(res));
  }

  ngOnDestroy() {
    this.browserService.listenForRouterEvent().unsubscribe();
    this.translateSubscription?.unsubscribe();
  }
}
