import { ApplicationConfig, Injectable, importProvidersFrom } from '@angular/core';
import { provideRouter, RouterStateSnapshot, TitleStrategy, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';
import { BrowserModule, Title } from '@angular/platform-browser';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { UserService } from '../store/user/user.service';
import { AnimalsService } from '../store/animals/animals.service';
import { BrowserService } from '../store/browser/browser.service';
import { RescueOrgService } from '../store/rescue-org/rescue-org.service';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {RescueOrgUsersService} from "../store/rescue-org-users/rescue-org-users.service";
// #### AKITA CONFIG START ####

if (environment.production) {
  enableAkitaProdMode();
}

akitaConfig({ resettable: true });

// #### AKITA CONFIG END ####

// ### i18n ###
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@Injectable({providedIn: 'root'})
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`ChiPet | ${title}`);
    }
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    ConfirmationService,
    MessageService,
    UserService,
    AnimalsService,
    RescueOrgUsersService,
    BrowserService,
    RescueOrgService,
    provideRouter(routes, withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy
    },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: "yyyy. MMMM dd." }
    },
    // Global module imports
    provideHttpClient(),
    importProvidersFrom([
      BrowserModule,
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ]),
    // Firebase module imports
    importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.firebaseConfig))),
    importProvidersFrom(provideAuth(() => getAuth())),
    importProvidersFrom(provideFirestore(() => getFirestore())),
    importProvidersFrom(provideFunctions(() => getFunctions())),
    importProvidersFrom(provideMessaging(() => getMessaging())),
    importProvidersFrom(providePerformance(() => getPerformance())),
    importProvidersFrom(provideStorage(() => getStorage())),
  ]
};
