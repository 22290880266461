import { inject }                from '@angular/core';
import { Router } from '@angular/router';
import { UserQuery }             from '../store/user/user.query';

export const loginGuard = () => {
  const userQuery = inject(UserQuery);
  const router = inject(Router);
  if (userQuery.isLoggedIn) {
    return router.parseUrl(router.url);
  } else {
    return true;
  }
}
